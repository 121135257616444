import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		imgList: {},
		user_id: window.localStorage.getItem('user_id'),
		is_test: window.localStorage.getItem('is_test')
	},
	mutations: {
		imgListTions: (state, data) => {
			state.imgList = data;
		},
		userIdTions: (state, data) => {
			state.user_id = data;
			window.localStorage.setItem('user_id', JSON.stringify(data));
		},
		isTest: (state, data) => {
			state.is_test = data;
			window.localStorage.setItem('is_test', JSON.stringify(data));
		},
		userName: (state, data) => {
			state.user_name = data;
			window.localStorage.setItem('user_name', JSON.stringify(data));
		},
		userMobile: (state, data) => {
			state.user_mobile = data;
			window.localStorage.setItem('user_mobile', JSON.stringify(data));
		},
		userUnit_name: (state, data) => {
			state.user_unit_name = data;
			window.localStorage.setItem('user_unit_name', JSON.stringify(data));
		},
		userStopat: (state, data) => {
			state.user_stopat = data;
			window.localStorage.setItem('user_stopat', JSON.stringify(data));
		},
		userOpenid: (state, data) => {
			state.user_openid = data;
			window.localStorage.setItem('user_openid', JSON.stringify(data));
		},
	},
	actions: {},
	modules: {}
})

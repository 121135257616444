import Vue from 'vue'
import VueRouter from 'vue-router'



const Login = () => import('../components/Login.vue')
const Home = () => import('../components/Home.vue')
const Preview = () => import('../components/Preview.vue')
const Preview_img = () => import('../components/Preview_img.vue')
const pay = () => import('../components/pay.vue')
const pay_zfb = () => import('../components/pay_zfb.vue')


Vue.use(VueRouter)

const routes = [{
		path: '/',
		redirect: '/login'
	}, //重定向
	{
		path: '/login',
		component: Login
	},
	{
		path: '/home',
		component: Home,
	},
	{
		path: '/preview',
		component: Preview,
	},
	{
		path: '/preview_img',
		component: Preview_img,
	},
	{
		path: '/pay',
		component: pay,
	},
	{
		path: '/pay_zfb',
		component: pay_zfb,
	},
]

const router = new VueRouter({
	routes
})

// 挂载路由导航守卫
router.beforeEach((to, from, next) => {
	// to 将要访问的路径  from 代表从哪个路径跳转而来 
	// next 代表放行  next() 放行  next('/login) 强制跳转

	if (to.path === '/login') return next();
	// 获取token
	const tokenStr = window.sessionStorage.getItem('token')
	if (!tokenStr) return next('login');
	next()
})

export default router
